import Controller from './controller'
import { elementId } from '../utilities'

export default class extends Controller {
  static get targets() {
    return ['template']
  }

  initialize() {
    this.items = []
  }

  addItem({ name, url }, removeCallback) {
    const element = this.templateTarget.content.cloneNode(true)
    const id = elementId()
    const item = {
      removeCallback,
    }

    element.querySelector('[data-selection]').id = id
    element.querySelector('[data-label]').textContent = name
    element.querySelector('[data-link]').href = url
    element.querySelector('button').addEventListener('click', () => {
      this.remove(item)
    })

    this.element.appendChild(element)
    item.element = document.getElementById(id)
    this.items.push(item)

    return item
  }

  remove(item, { runCallback = true } = {}) {
    this.element.removeChild(item.element)
    this.items.splice(this.items.indexOf(item), 1)

    if (runCallback) {
      item.removeCallback()
    }
  }

  reset() {
    this.items.forEach(item => {
      this.remove(item, {
        runCallback: false,
      })
    })
  }
}
