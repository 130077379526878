import 'intersection-observer'
import Controller from './controller'

export default class extends Controller {
  initialize() {
    this.onscreenClass = 'is-onscreen'
  }

  connect() {
    this.offset = 0.25

    if (this.element.getAttribute('data-auto')) {
      this.offset = 0
    }

    this.observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: this.offset,
    }

    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          observer.disconnect()
          this.element.classList.add(this.onscreenClass)
        }
      })
    }, this.observerOptions)

    setTimeout(() => this.observer.observe(this.element), 500)
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}
