import Controller from './controller'
import { PullYoutube } from '../youtube-api'

export default class extends Controller {
  static get targets() {
    return ['iframe', 'button']
  }

  initialize() {
    PullYoutube(() => this.start())
  }

  start() {
    this.element.classList.add('is-loaded')
    this.buttonTarget.addEventListener('click', () => this.begin())
  }

  begin() {
    this.src = this.iframeTarget.getAttribute('data-src')
    this.iframeTarget.setAttribute('src', this.src)

    this.player = new YT.Player(this.iframeTarget, {
      origin: window.location.origin,
      events: {
        onReady: () => this.play(),
      },
      playerVars: {
        origin: window.location.origin,
      },
    })
  }

  play() {
    this.element.classList.add('is-active')
    this.player.playVideo()
  }
}
