import { merge } from 'lodash-es'
import randomColor from 'randomcolor'
import Controller from './controller'
import { colorLuma, isTouch } from '../utilities'

export default class extends Controller {
  static get targets() {
    return ['map', 'layer', 'table', 'selections', 'regions', 'region', 'clearStudy']
  }

  static get options() {
    return {
      mapboxAccessToken:
        'pk.eyJ1IjoiYXB0Z3JvdXAiLCJhIjoiY2tidGwydHI0MGFoNzJ6bGl1eGxpaG5wMyJ9.GUkXNbzkRSk_Y_839_iSOA',
      mapboxOptions: {
        style: 'mapbox://styles/aptgroup/ckbwedvdx11my1is28fgrg4m4',
        center: [10, 61.5],
        zoom: 3.5,
        minZoom: 2,
      },
    }
  }

  initialize() {
    if (!window.mapboxgl) {
      console.error('Mapbox GL failed to load')
      return
    }

    const loadStudies = new Promise(this.loadStudies.bind(this))
    const createMap = new Promise(this.createMap.bind(this))
    const createTable = new Promise(this.createTable.bind(this))
    const createSelections = new Promise(this.createSelections.bind(this))

    Promise.all([loadStudies, createMap, createTable, createSelections]).then(() => {
      this.createRegions()
      this.addStudies()
      this.setTableData(this.studies)

      this.map.on('click', e => {
        const features = this.map.queryRenderedFeatures(e.point)
        if (features.length) {
          if (this.activeStudy) {
            if (isTouch) {
              this.hoverFeature(features[0])
            } else if (features[0].properties.cluster_id) {
              this.showCluster(features[0])
            }
          } else {
            this.showStudy(features[0].source)
          }
        }
      })

      this.map.on('zoom', () => {
        this.clearActiveFeatures()
      })

      if (!isTouch) {
        this.map.on('mousemove', e => {
          const features = this.map.queryRenderedFeatures(e.point)
          if (features.length) {
            this.hoverFeature(features[0])
          }
        })
      }

      this.mapTarget.addEventListener('popup:click-marker', () => {
        if (this.activeFeatures && this.activeFeatures[0].properties.cluster_id) {
          this.showCluster(this.activeFeatures[0])
        }
      })
      this.mapTarget.addEventListener('popup:close', () => {
        this.hoveredFeatureId = null
        this.clearActiveFeatures()
      })

      this.element.addEventListener('table:show', ev => {
        if (ev.detail.studyId) {
          this.showStudy(ev.detail.studyId)
          this.mapTarget.scrollIntoView({ behavior: 'smooth' })
        }
      })
      this.element.addEventListener('table:filter', ev => {
        this.filteredStudyIds = ev.detail.studyIds
        this.setStudiesVisibility()
      })
    })
  }

  hoverFeature(feature) {
    if (feature.id === this.hoveredFeatureId) return
    this.hoveredFeatureId = feature.id

    this.clearActiveFeatures()

    // If not a cluster or a single marker, clear existing active features
    if (!feature.properties.cluster_id && !feature.properties.studyId) {
      return
    }

    if (this.activeStudy) {
      this.activeFeatures = [feature]
      this.showFeatureWells(feature)
    } else {
      this.activeFeatures = this.map.querySourceFeatures(feature.source)
      this.map.moveLayer(`${feature.source}-clusters`)
      this.map.moveLayer(`${feature.source}-cluster-count`)
      this.showFeatureStudy(feature)
    }

    this.activeFeatures.forEach(f => {
      f.source = feature.source
      this.map.setFeatureState(f, { hover: true })
    })

    this.map.getCanvas().style.cursor =
      !this.activeStudy || feature.properties.cluster_id ? 'pointer' : 'default'
  }

  unhoverFeature() {}

  clearActiveFeatures() {
    this.hidePopup()

    if (!this.activeFeatures) return

    this.map.getCanvas().style.cursor = ''

    this.activeFeatures.forEach(f => {
      this.map.removeFeatureState(f, 'hover')
    })

    this.activeFeatures = null
  }

  // Zoom in to a cluster's markers
  showCluster(feature) {
    const clusterId = feature.properties.cluster_id

    this.map.getSource(feature.source).getClusterLeaves(feature.id, 999, 0, (error, wells) => {
      if (error) {
        console.error(error)
        return
      }

      // If all the wells in this cluster have the same coordinates, return without trying to zoom in
      if (
        wells.every(
          well =>
            well.geometry.coordinates[0] === wells[0].geometry.coordinates[0] &&
            well.geometry.coordinates[1] === wells[0].geometry.coordinates[1],
        )
      ) {
        return
      }

      this.clearActiveFeatures()

      this.map.getSource(feature.source).getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          console.error(err)
          return
        }

        this.map.easeTo({
          center: feature.geometry.coordinates,
          zoom,
        })
      })
    })
  }

  setTableData(studies) {
    const data = studies.map(study => study.geoJson.properties)
    this.table.setData(data)
  }

  createMap(resolve) {
    window.mapboxgl.accessToken = this.options.mapboxAccessToken

    this.map = new mapboxgl.Map(
      merge(this.options.mapboxOptions, {
        container: this.mapTarget,
      }),
    )

    // Disable map zoom when using scroll
    this.map.scrollZoom.disable()

    // Add zoom and rotation controls to the map
    this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-left')

    this.map.on('load', () => {
      this.layerTargets.forEach(checkbox => {
        // Set initial layer visibility
        this.setLayerVisibility(checkbox)

        // Toggle layer visibility on checkbox change
        checkbox.addEventListener('change', () => {
          this.setLayerVisibility(checkbox)
        })
      })

      resolve()
    })
  }

  loadStudies(resolve, reject) {
    fetch('/studies.json')
      .then(response => response.json())
      .then(data => {
        this.studies = data.data.map(this.formatStudyData.bind(this))
        this.studies = this.studies.filter(Boolean) // Filter out invalid studies (e.g. with no coordinates)
        resolve()
      })
      .catch(error => {
        console.error(error)
        reject()
      })
  }

  loadStudyWells(studyId, resolve, reject) {
    fetch(`/wells.json?study_id=${studyId}`)
      .then(response => response.json())
      .then(data => {
        resolve(data.data)
      })
      .catch(error => {
        console.error(error)
        reject()
      })
  }

  createSelections(resolve) {
    const check = setInterval(() => {
      this.selections = this.application.getControllerForElementAndIdentifier(
        this.selectionsTarget,
        'studies-selections',
      )
      if (this.selections) {
        clearInterval(check)
        resolve()
      }
    }, 50)
  }

  createTable(resolve) {
    const check = setInterval(() => {
      this.table = this.application.getControllerForElementAndIdentifier(
        this.tableTarget,
        'studies-table',
      )
      if (this.table) {
        clearInterval(check)
        resolve()
      }
    }, 50)
  }

  addStudies() {
    this.studies.forEach(this.addStudy.bind(this))
  }

  addStudy(study) {
    const source = study.id
    const textColor = colorLuma(study.geoJson.properties.color) > 150 ? '#000' : '#fff'

    this.map.addSource(source, {
      type: 'geojson',
      data: study.geoJson,
      cluster: true,
      clusterMaxZoom: 21, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    })

    this.map.addLayer({
      id: `${study.id}-clusters`,
      type: 'circle',
      source,
      paint: {
        'circle-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#000',
          study.geoJson.properties.color,
        ],
        'circle-radius': ['case', ['boolean', ['has', 'point_count'], false], 18, 8],
        'circle-stroke-width': 2,
        'circle-stroke-color': '#fff',
      },
    })

    this.map.addLayer({
      id: `${study.id}-cluster-count`,
      type: 'symbol',
      source,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-size': 15,
        'text-allow-overlap': true,
      },
      paint: {
        'text-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#fff', textColor],
      },
    })

    study.layers = [`${study.id}-clusters`, `${study.id}-cluster-count`]
  }

  showFeatureStudy(feature) {
    const study = this.getStudy(feature.source)

    this.hidePopup()
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 12,
      className: 'mapboxgl-popup--study',
    })

    const markup = `
      <div class="popup">
        <h5>${study.geoJson.properties.name}</h5>
      </div>
    `

    this.popup
      .setLngLat(feature.geometry.coordinates)
      .setHTML(markup)
      .addTo(this.map)
  }

  showFeatureWells(feature) {
    const study = this.getStudy(feature.source)

    if (feature.properties.cluster_id) {
      this.map.getSource(feature.source).getClusterLeaves(feature.id, 999, 0, (err, wells) => {
        if (err) {
          console.error(err)
          return
        }

        const wellIds = wells.map(well => well.id)
        this.showWells(wellIds, study, feature)
      })
    } else {
      this.showWells([feature.properties.id], study, feature)
    }
  }

  showWells(wellIds, study, feature) {
    Promise.resolve(study.wells).then(allWells => {
      const wells = allWells.filter(well => wellIds.indexOf(well.id) > -1)
      const itemsMarkup = wells.map((well, i) => {
        const data = []
        Object.entries(well.data).forEach(([name, value]) => {
          if (value) {
            data.push(name)
          }
        })
        const definitions = []
        if (well.majorBasin) {
          definitions.push(`
            <dt>Major basin</dt>
            <dd>${well.majorBasin}</dd>
          `)
        }
        if (well.subBasin) {
          definitions.push(`
            <dt>Sub-basin</dt>
            <dd>${well.subBasin}</dd>
          `)
        }
        if (data.length > 0) {
          definitions.push(`
            <dt>Data</dt>
            <dd>${data.join(', ')}</dd>
          `)
        }
        return `
          <li data-target="studies-popup.item" ${i === 0 ? 'class="is-active"' : ''}>
            <h4>${well.name}</h4>
            <dl>${definitions.join('')}</dl>
          </li>
        `
      })

      let controls = ''
      if (wells.length > 1) {
        controls += `
          <button data-action="click->studies-popup#previous">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15.498 2.217l1.435 1.435L8.585 12l8.348 8.348-1.435 1.435L5.717 12l9.781-9.784z" fill="currentColor"/>
            </svg>
          </button>
          <p><span data-target="studies-popup.count">1</span> of ${wells.length}</p>
          <button data-action="click->studies-popup#next">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.502 21.783l-1.435-1.435L15.415 12 7.067 3.652l1.435-1.435L18.283 12l-9.781 9.784z" fill="currentColor"/>
            </svg>
          </button>
        `
      }

      const markup = `
        <div class="popup" data-controller="studies-popup">
          <div class="popup__controls">
            ${controls}
            <button data-action="click->studies-popup#close">
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 10.609L18.609 4 20 5.391 13.391 12 20 18.609 18.609 20 12 13.391 5.391 20 4 18.609 10.609 12 4 5.391 5.391 4 12 10.609z" fill="currentColor"/>
              </svg>
            </button>
          </div>
          <ul>
            ${itemsMarkup.join('')}
          </ul>
          <div class="popup__marker" data-action="click->studies-popup#clickMarker"></div>
        </div>
      `

      this.hidePopup()
      this.popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 12,
        className: wells.length > 1 ? 'is-multiple' : 'is-single',
      })

      this.popup
        .setLngLat(feature.geometry.coordinates)
        .setHTML(markup)
        .addTo(this.map)
    })
  }

  hidePopup() {
    if (!this.popup) return
    this.popup.remove()
    this.popup = null
  }

  showStudy(id) {
    const study = this.getStudy(id)
    if (!study) return

    if (this.activeStudy) {
      if (id === this.activeStudy.id) return
      this.selections.remove(this.activeStudy.selection, { runCallback: false })
      this.table.setActiveStudy(null)
    } else {
      // Cache current map position
      this.previousPosition = {
        center: this.map.getCenter(),
        zoom: this.map.getZoom(),
      }
    }

    this.activeStudy = study

    this.clearActiveFeatures()

    if (!study.wells) {
      study.wells = new Promise(this.loadStudyWells.bind(this, study.id))
    }

    this.setStudiesVisibility()

    // Extra top space on mobile for overlaid study box
    // Extra bottom space on desktop for overlapping table
    const padding = this.isMobileLayout()
      ? { top: 110, bottom: 30, left: 30, right: 30 }
      : { top: 50, bottom: 140, left: 50, right: 50 }

    this.map.fitBounds(study.bounds, {
      linear: true,
      maxZoom: 8,
      padding,
    })

    // Show all studies when current selection 'X' is clicked
    study.selection = this.selections.addItem(study.geoJson.properties, () => {
      this.activeStudy = null
      this.clearActiveFeatures()
      this.setStudiesVisibility()

      // Restore previous map position
      this.map.easeTo({
        center: this.previousPosition.center,
        zoom: this.previousPosition.zoom,
      })
      this.previousPosition = null

      this.table.setActiveStudy(null)
    })

    // Highlight active study in table
    this.table.setActiveStudy(study.id)
  }

  getStudy(id) {
    for (let i = 0; i < this.studies.length; i++) {
      if (this.studies[i].id === id) return this.studies[i]
    }

    return null
  }

  setStudiesVisibility() {
    const visibleStudies = []

    this.studies.forEach(study => {
      let visible = true

      if (this.activeStudy && study.id !== this.activeStudy.id) {
        visible = false
      }

      if (this.activeRegions.indexOf(study.geoJson.properties.region) === -1) {
        visible = false
      }

      if (this.filteredStudyIds && this.filteredStudyIds.indexOf(study.id) === -1) {
        visible = false
      }

      if (visible) {
        visibleStudies.push(study)
      }

      study.layers.forEach(layer => {
        this.map.setLayoutProperty(layer, 'visibility', visible ? 'visible' : 'none')
      })
    })

    // Show/hide 'Show all studies' button depending on whether all studies are currently shown
    if (visibleStudies.length < this.studies.length) {
      this.clearStudyTarget.classList.add('is-shown')
    } else {
      this.clearStudyTarget.classList.remove('is-shown')
    }
  }

  createRegions() {
    const regions = this.studies.reduce((arr, study) => {
      if (arr.indexOf(study.geoJson.properties.region) === -1) {
        arr.push(study.geoJson.properties.region)
      }
      return arr
    }, [])

    regions.sort()
    this.activeRegions = regions

    const template = this.regionsTarget.querySelector('template')
    const fragment = document.createDocumentFragment()

    regions.forEach(region => {
      const checkbox = document.importNode(template.content, true)
      checkbox.querySelector('[data-input]').setAttribute('value', region)
      checkbox.querySelector('[data-label]').textContent = region
      fragment.appendChild(checkbox)
    })

    this.regionsTarget.removeChild(template)
    this.regionsTarget.appendChild(fragment)
    this.regionsTarget.classList.remove('is-loading')
  }

  setActiveRegions() {
    this.activeRegions = []
    this.regionTargets.forEach(checkbox => {
      if (checkbox.checked) {
        this.activeRegions.push(checkbox.value)
      }
    })
    this.table.setActiveRegions(this.activeRegions)
  }

  changeActiveRegions() {
    this.setActiveRegions()
    this.setStudiesVisibility()
  }

  setLayerVisibility(checkbox) {
    this.map.setLayoutProperty(checkbox.value, 'visibility', checkbox.checked ? 'visible' : 'none')
  }

  formatStudyData(data) {
    if (data.coordinates.length === 0) return null // Hide studies with no coordinates

    const study = {
      id: data.id,
      bounds: new mapboxgl.LngLatBounds(),
    }
    const features = []
    const color = randomColor({
      luminosity: 'bright',
      seed: data.url, // Use seed to keep study colors the same across visits
    })

    data.coordinates
      .filter(point => point.x && point.y) // Ignore points without coordinates
      .forEach(point => {
        features.push({
          type: 'Feature',
          id: point.id,
          properties: {
            id: point.id,
            studyId: data.id,
          },
          geometry: {
            type: 'Point',
            coordinates: [point.x, point.y],
          },
        })

        study.bounds.extend([point.x, point.y])
      })

    study.geoJson = {
      type: 'FeatureCollection',
      id: study.id,
      properties: {
        id: data.id,
        name: data.name,
        region: data.region,
        url: data.url,
        color,
      },
      features,
    }

    return study
  }

  reset() {
    this.clearActiveFeatures()

    // Check all region options
    this.regionTargets.forEach(checkbox => {
      checkbox.checked = true
    })
    this.setActiveRegions()

    // Show all studies
    this.activeStudy = null
    this.filteredStudyIds = null
    this.selections.reset()
    this.setStudiesVisibility()

    this.table.reset()

    this.map.easeTo({
      center: this.options.mapboxOptions.center,
      zoom: this.options.mapboxOptions.zoom,
    })
  }

  closeFilter() {
    this.element.classList.remove('has-filters-toggled')
  }

  toggleFilter() {
    this.element.classList.toggle('has-filters-toggled')
  }

  stopPropagation(ev) {
    ev.stopPropagation()
  }

  isMobileLayout() {
    return window.innerWidth < 1024
  }
}
