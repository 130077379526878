import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    const links = JSON.parse(this.element.dataset.links)

    if (links) this.setupLinks(links)
  }

  setupLinks(links) {
    this.element.querySelectorAll('a').forEach((elem, i) => {
      elem.setAttribute('href', links[i])
    })
  }
}
