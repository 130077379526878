import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['close', 'list', 'toggle', 'inner']
  }

  initialize() {
    this.close = this.close.bind(this)
    this.bodyNavStuckClass = 'has-fixed-nav'
    this.elementDropdownOpenClass = 'has-dropdown-open'
  }

  connect() {
    this.isOpen = this.element.classList.contains('is-open')

    if (this.isOpen) {
      this.addEvents()
    }
  }

  disconnect() {
    if (this.isOpen) {
      this.removeEvents()
    }
  }

  toggle() {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    this.isOpen = true
    disableBodyScroll(this.listTarget, {
      reserveScrollBarGap: true,
    })
    this.element.classList.add('is-open')

    // Scroll down to top of header.
    const headerTop = this.element.getBoundingClientRect().top
    if (window.pageYOffset < headerTop) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    this.toggleTargets.forEach(element => {
      element.setAttribute('aria-expanded', true)
    })

    this.addEvents()
  }

  close(event) {
    if (
      event &&
      ((event.type === 'keydown' && event.key !== 'Escape') ||
        (event.type === 'click' && this.innerTarget.contains(event.target)))
    ) {
      return
    }

    this.isOpen = false
    enableBodyScroll(this.listTarget)
    this.element.classList.remove('is-open')

    this.toggleTargets.forEach(element => {
      element.setAttribute('aria-expanded', false)
    })

    this.removeEvents()
  }

  addEvents() {
    if (this.hasCloseTarget) {
      this.closeTarget.addEventListener('click', this.close)
    }

    window.addEventListener('click', this.close)
    window.addEventListener('keydown', this.close)
  }

  removeEvents() {
    if (this.hasCloseTarget) {
      this.closeTarget.removeEventListener('click', this.close)
    }

    window.removeEventListener('click', this.close)
    window.removeEventListener('keydown', this.close)
  }

  onDropdownOpen(ev) {
    if (this.openDropdown) {
      this.openDropdown.close()
    }

    this.openDropdown = ev.detail.dropdown
    this.element.classList.add(this.elementDropdownOpenClass)
  }

  onDropdownClose(ev) {
    // If a new dropdown hasn't been opened.
    if (ev.detail.dropdown === this.openDropdown) {
      this.openDropdown = null
      this.element.classList.remove(this.elementDropdownOpenClass)
    }
  }

  onStuck() {
    document.body.classList.add(this.bodyNavStuckClass)
  }

  onUnstuck() {
    document.body.classList.remove(this.bodyNavStuckClass)
  }
}
