import { merge } from 'lodash-es'
import { Controller } from 'stimulus'

export default class extends Controller {
  constructor(...params) {
    super(...params)
    this.setOptions()
  }

  setOptions() {
    if (!this.constructor.options) {
      return
    }

    let overrides = this.data.get('options')

    if (overrides) {
      try {
        overrides = JSON.parse(overrides)
      } catch (error) {
        console.error('Error parsing component options', this.element, overrides)
        overrides = {}
      }
    }

    this.options = merge({}, this.constructor.options || {}, overrides || {})
  }

  emit(name, options, element = this.element) {
    options = {
      bubbles: false,
      cancelable: false,
      detail: null,
      ...options,
    }

    let ev

    if (typeof CustomEvent === 'function') {
      ev = new CustomEvent(name, options)
    } else {
      ev = document.createEvent('CustomEvent')
      ev.initCustomEvent(name, options.bubbles, options.cancelable, options.detail)
    }

    element.dispatchEvent(ev)
  }
}
