import tabbable from 'tabbable'
import Controller from './controller'
import { elementId } from '../utilities'

export default class extends Controller {
  static get targets() {
    return ['dropdown', 'trigger', 'close', 'group']
  }

  get focusables() {
    this.cachedFocusables = this.cachedFocusables || tabbable(this.dropdownTarget)
    return this.cachedFocusables
  }

  initialize() {
    this.dropdownTarget.id = this.dropdownTarget.id || elementId()
    this.triggerTarget.id = this.triggerTarget.id || elementId()
    this.dropdownTarget.setAttribute('aria-labelledby', this.triggerTarget.id)
    this.triggerTarget.setAttribute('aria-controls', this.dropdownTarget.id)
    this.triggerTarget.setAttribute('aria-haspopup', true)

    this.close = this.close.bind(this)
    this.focus = this.focus.bind(this)
  }

  connect() {
    this.isOpen = this.element.classList.contains('is-open')

    if (this.isOpen) {
      this.addEvents()
    }
  }

  disconnect() {
    if (this.isOpen) {
      this.removeEvents()
    }
  }

  open(callback) {
    this.isOpen = true
    this.element.classList.add('is-open')
    this.triggerTarget.setAttribute('aria-expanded', true)
    this.addEvents()

    // Tell nav that a dropdown is open (if dropdown is within nav).
    this.emit('dropdown:open', { bubbles: true, detail: { dropdown: this } })

    if (callback) {
      setTimeout(callback, 50)
    }
  }

  close(event) {
    if (
      event &&
      ((event.type === 'keydown' && event.key !== 'Escape') ||
        (event.type === 'click' &&
          this.element.contains(event.target) &&
          (!this.hasCloseTarget || !this.closeTarget.contains(event.target))))
    ) {
      return
    }

    this.isOpen = false
    this.element.classList.remove('is-open')
    this.triggerTarget.removeAttribute('aria-expanded')
    this.removeEvents()

    // Tell nav that a dropdown has been closed (if dropdown is within nav).
    this.emit('dropdown:close', { bubbles: true, detail: { dropdown: this } })
  }

  toggle() {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  focus(event) {
    const up = event.key === 'ArrowUp'
    const right = event.key === 'ArrowRight'
    const down = event.key === 'ArrowDown'
    const left = event.key === 'ArrowLeft'

    if (up || right || down || left) {
      // Prevent default arrow action of scrolling the page, and stop arrow events bubbling up to
      // `nav_controller#focus` (if dropdown is inside nav).
      event.preventDefault()
      event.stopPropagation()
    }

    if (right || left) {
      // Switch focus to next/previous dropdown group.
      const group = event.target.closest('[data-target="dropdown.group"]')

      // Do nothing if currently focused element is not inside a group.
      if (!group) {
        return
      }

      const index = this.groupTargets.indexOf(group)
      const nextGroup = this.groupTargets[index + (right ? 1 : -1)]

      if (nextGroup) {
        const focusables = tabbable(nextGroup)

        if (focusables.length > 0) {
          focusables[0].focus()
        }
      } else {
        this.triggerTarget.focus()
      }
    } else if (up || down) {
      // Switch focus to next/previous focusable element.
      const index = this.focusables.indexOf(event.target)
      const nextFocusable = this.focusables[index + (down ? 1 : -1)]

      if (nextFocusable) {
        nextFocusable.focus()
      } else {
        this.triggerTarget.focus()
      }
    }
  }

  // Open dropdown and move focus to its first or last focusable element.
  goTo(event) {
    const up = event.key === 'ArrowUp'
    const down = event.key === 'ArrowDown'

    if (!up && !down) {
      return
    }

    // Prevent default arrow action of scrolling the page, and stop arrow events bubbling up to
    // `nav_controller#focus` (if dropdown is inside nav).
    event.preventDefault()
    event.stopPropagation()

    const focus = () => {
      const element = this.focusables[up ? this.focusables.length - 1 : 0]

      if (element) {
        element.focus()
      }
    }

    if (this.isOpen) {
      focus()
    } else {
      this.open(focus)
    }
  }

  addEvents() {
    this.dropdownTarget.addEventListener('keydown', this.focus)

    if (this.hasCloseTarget) {
      this.closeTarget.addEventListener('click', this.close)
    }

    window.addEventListener('click', this.close)
    window.addEventListener('keydown', this.close)
  }

  removeEvents() {
    this.dropdownTarget.removeEventListener('keydown', this.focus)

    if (this.hasCloseTarget) {
      this.closeTarget.removeEventListener('click', this.close)
    }

    window.removeEventListener('click', this.close)
    window.removeEventListener('keydown', this.close)
  }
}
