import Controller from './controller'

export default class extends Controller {
  static get targets() {
    return ['member', 'prev', 'next', 'page']
  }

  initialize() {
    this.perPage = Number(this.element.dataset.perPage)
    this.page = Number(this.element.dataset.currentPage)
    this.filterRows()
  }

  filterRows() {
    this.memberTargets.forEach(el => {
      el.style.display = 'none'
    })

    const lower = (this.page - 1) * this.perPage
    const upper = this.page * this.perPage

    this.filteredMemberTargets.forEach((el, i) => {
      if (i >= lower && i < upper) {
        el.style.display = 'block'
      }
    })

    this.totalPages = Math.ceil(this.filteredMemberTargets.length / this.perPage)

    this.togglePagination()
  }

  get filteredMemberTargets() {
    if (!this.department) return this.memberTargets

    return this.memberTargets.filter(el => el.dataset.departments.includes(this.department))
  }

  filterByDepartment(e) {
    this.page = 1
    this.department = e.target.value
    this.filterRows()
  }

  prevUrl(e) {
    e.preventDefault()
    this.page--
    this.filterRows()
    this.scrollIntoView()
  }

  nextUrl(e) {
    e.preventDefault()
    this.page++
    this.filterRows()
    this.scrollIntoView()
  }

  goToPage(e) {
    e.preventDefault()
    this.page = Number(e.target.parentNode.dataset.page)
    this.filterRows()
    this.scrollIntoView()
  }

  scrollIntoView() {
    document.getElementById('team-members').scrollIntoView({ behavior: 'smooth' })
  }

  togglePagination() {
    this.pageTargets.forEach((el, i) => {
      el.classList.toggle('is-active', Number(el.dataset.page) === this.page)

      const pageExists = i < this.totalPages

      el.style.display = pageExists ? 'flex' : 'none'
    })

    const onLastPage = this.page === this.totalPages
    const onFirstPage = this.page === 1

    this.nextTarget.style.visibility = onLastPage ? 'hidden' : 'visible'
    this.prevTarget.style.visibility = onFirstPage ? 'hidden' : 'visible'
  }
}
