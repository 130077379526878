import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['content']
  }

  initialize() {
    this.links = this.contentTarget.querySelectorAll('a[href*="glossary/"]')
    this.toolTipArray = []

    this.links.forEach(link => this.setUp(link))
  }

  setUp(link) {
    link.style.position = 'relative'
    this.toolTipArray.push(false)

    link.addEventListener('click', event => this.listener(event))
  }

  listener(event) {
    event.preventDefault()
    this.link = event.currentTarget

    for (let i = 0; i < this.links.length; i++) {
      if (this.links[i] === this.link) {
        this.index = i
      }
    }

    if (this.toolTipArray[this.index]) {
      this.toggle(this.toolTipArray[this.index])
      return
    }

    this.link.classList.add('is-loading')

    this.xmlhttp = new XMLHttpRequest()
    this.xmlhttp.open('GET', `/api${event.currentTarget.pathname}`, true)

    this.xmlhttp.onreadystatechange = this.request.bind(this)
    this.xmlhttp.send(null)
  }

  request() {
    if (this.xmlhttp.readyState === 4) {
      if (this.xmlhttp.status === 200) {
        this.fill(this.index, this.link, JSON.parse(this.xmlhttp.responseText))
      }
    }
  }

  fill(index, link, res) {
    const template = document.getElementById('tooltip-tmpl')
    const tootip = template.getElementsByClassName('js-tooltip-content')[0].cloneNode(true)
    const trigger = tootip.getElementsByClassName('js-tooltip-trigger')
    const body = tootip.getElementsByClassName('js-tooltip-body')
    const anchor = tootip.getElementsByClassName('js-tooltip-link')
    const linkText = link.textContent
    const linkHref = link.href

    tootip.style.display = 'inline'
    tootip.removeAttribute('data-target')
    link.textContent = ''

    trigger[0].textContent = linkText
    body[0].textContent = res.field_glossarySynopsis
    anchor[0].textContent = res.title
    anchor[0].href = linkHref

    link.parentNode.insertBefore(tootip, link)
    link.parentNode.removeChild(link)

    this.toggle(tootip)

    this.toolTipArray[index] = tootip

    this.events(this.toolTipArray[index])
  }

  events(el) {
    const trigger = el.getElementsByClassName('js-tooltip-trigger')
    const off = el.getElementsByClassName('js-tooltip-close')

    trigger[0].addEventListener('click', () => this.toggle(el))
    off[0].addEventListener('click', () => this.close())
  }

  close() {
    const tooltips = document.getElementsByClassName('js-tooltip-content')

    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].classList.remove('is-visible')
    }
  }

  toggle(element) {
    if (element.classList.contains('is-visible')) {
      this.close()
      element.classList.remove('is-visible')
    } else {
      this.close()
      this.resize(element)
      element.classList.add('is-visible')
    }
  }

  resize(element) {
    const inner = element.getElementsByClassName('js-tooltip-inner')
    const triangle = element.getElementsByClassName('js-tooltip-triangle')
    const rect = inner[0].getBoundingClientRect()
    const { left } = rect.left
    const right = document.getElementsByTagName('html')[0].offsetWidth - rect.right

    console.log(right)
    console.log(left)

    if (left < 0) {
      inner[0].style.transform = `translateX(${Math.abs(left - 20)}px)`
      triangle[0].style.transform = `translateX(${left - 20}px)`
    } else if (right < 0) {
      inner[0].style.transform = `translateX(${right - 20}px)`
      triangle[0].style.transform = `translateX(${Math.abs(right - 20)}px)`
    } else {
      inner[0].style.transform = 'none'
      triangle[0].style.transform = 'none'
    }
  }
}
