import anime from 'animejs'
import 'intersection-observer'
import Controller from './controller'

export default class extends Controller {
  static get targets() {
    return ['layers', 'layer', 'boxes', 'box', 'lines', 'cover', 'text', 'graphic']
  }

  initialize() {
    this.duration = 3000
    this.delay = 2000
    this.easing = 'easeInOutSine'

    this.setStage()

    setTimeout(() => this.run(), 1000)
  }

  run() {
    // Fade in layers
    anime({
      targets: this.layerTargets,
      opacity: 1,
      duration: 1000,
      delay: (el, i) => this.delay / 2 + i * 50,
      easing: this.easing,
    })

    // Reset layer x position
    anime({
      targets: this.layerTargets,
      translateX: 0,
      duration: this.duration,
      delay: (el, i) => this.delay + i * 100,
      easing: this.easing,
    })

    // Slide layers container right
    anime({
      targets: this.layersTarget,
      translateX: '10%',
      duration: this.duration,
      delay: this.delay,
      easing: this.easing,
    })

    // Fade/slide in boxes
    anime({
      targets: this.boxTargets,
      translateX: '10%',
      opacity: 1,
      duration: this.duration,
      delay: (el, i) => -i * 400 + this.delay * 1.25,
      easing: this.easing,
    })

    // Fade/slide in lines
    anime({
      targets: this.linesTargets,
      translateX: 0,
      opacity: 1,
      duration: this.duration,
      delay: (el, i) => -i * 400 + this.delay * 1.25,
      easing: this.easing,
    })

    // Rotate and slide cover
    anime({
      targets: this.coverTarget,
      rotate: 0,
      translateX: '10%',
      duration: this.duration,
      delay: this.delay,
      easing: this.easing,
    })

    // Fade out graphic
    anime({
      targets: this.graphicTarget,
      opacity: 0.1,
      filter: 'grayscale(100%)',
      duration: this.duration / 2,
      delay: this.delay + this.duration,
      easing: this.easing,
    })

    // Fade in text
    anime({
      targets: this.textTarget,
      opacity: 1,
      translateY: 0,
      duration: this.duration,
      delay: this.delay * 1.25 + this.duration,
      easing: this.easing,
    })
  }

  setStage() {
    // Set text
    anime({
      targets: this.textTarget,
      translateY: '50%',
      opacity: 0,
      duration: 0,
    })

    // Set layers
    anime({
      targets: this.layerTargets,
      opacity: 0,
      duration: 0,
    })

    anime({
      targets: this.layerTargets[0],
      translateX: '-34%',
      duration: 0,
    })

    anime({
      targets: this.layerTargets[1],
      translateX: '-13%',
      duration: 0,
    })

    anime({
      targets: this.layerTargets[2],
      translateX: '12%',
      duration: 0,
    })

    anime({
      targets: this.layerTargets[3],
      translateX: '34%',
      duration: 0,
    })

    // Set cover
    anime({
      targets: this.coverTarget,
      rotate: -75,
      duration: 0,
    })

    anime({
      targets: [this.boxTargets, this.linesTargets],
      translateX: '-14%',
      opacity: 0,
      duration: 0,
    })
  }
}
