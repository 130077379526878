import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['trigger', 'close', 'inner', 'content']
  }

  initialize() {
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this.windowOnClick = this.windowOnClick.bind(this)
    this.isShown = this.element.classList.contains('is-open')
    this.disableScroll = this.data.get('scroll') === undefined

    const modalTrigger = this.contentTarget.querySelector('a[href="#modal"]')
    if (modalTrigger) {
      modalTrigger.addEventListener('click', this.show)
    }

    this.triggerTargets.forEach(elem => elem.addEventListener('click', this.show))

    document.addEventListener('keydown', this.hide)
  }

  show(event) {
    console.log(document.getElementsByTagName('html')[0])
    event.preventDefault()
    document.getElementsByTagName('html')[0].classList.add('is-scrolllocked')
    this.element.classList.add('is-open')

    if (this.disableScroll) {
      disableBodyScroll(this.modalTarget, {
        reserveScrollBarGap: true,
      })
    }

    this.closeTargets.forEach(element => {
      element.addEventListener('click', this.hide)
    })

    setTimeout(() => {
      this.contentTarget.addEventListener('click', this.windowOnClick)
    }, 0)
    return false
  }

  hide(event) {
    document.getElementsByTagName('html')[0].classList.remove('is-scrolllocked')

    if (event.keyCode === 27) {
      this.hide
    }
    this.element.classList.remove('is-open')

    if (this.disableScroll) {
      enableBodyScroll(this.modalTarget)
    }

    this.closeTarget.removeEventListener('click', this.hide)
    this.contentTarget.removeEventListener('click', this.windowOnClick)
  }

  windowOnClick(event) {
    if (!this.isDescendant(this.contentTarget, event.target)) {
      this.hide()
    }
  }

  isDescendant(parent, child) {
    let node = child.parentNode

    while (node != null) {
      if (node === parent) {
        return true
      }
      node = node.parentNode
    }

    return false
  }
}
