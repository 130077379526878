import { merge } from 'lodash-es'
import Controller from './controller'

export default class extends Controller {
  static get options() {
    return {}
  }

  initialize() {
    if (window.google && window.google.maps) {
      this.createMap()
    } else {
      document.addEventListener('mapsReady', this.createMap.bind(this))
    }
  }

  createMap() {
    const options = merge({}, this.options, {
      mapTypeControl: false,
      styles: [
        {
          stylers: [
            {
              hue: '#31A03A',
            },
            {
              saturation: -10,
            },
            {
              invert_lightness: true,
            },
            {
              lightness: 10,
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#90daa0',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi.business',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'water',
          stylers: [
            {
              color: '#31a03a',
            },
          ],
        },
      ],
    })

    this.map = new window.google.maps.Map(this.element, options)

    this.marker = new window.google.maps.Marker({
      position: this.options.center,
      map: this.map,
      clickable: false,
    })
  }
}
