import Controller from './controller'

const options = {
  activeClass: 'is-active',
  disabledClass: 'is-disabled',
}

export default class extends Controller {
  static get targets() {
    return ['button', 'content']
  }

  initialize() {
    this.options = options
    this.count = this.buttonTargets.length
    this.last = this.count - 1
    this.hash420 = this.buttonTargets[0].hasAttribute('data-hash')
    this.active = this.buttonTargets.indexOf(
      this.buttonTargets.filter(element => element.classList.contains(this.options.activeClass))[0],
    )

    if (this.hash420) {
      this.hashCheck()
    }

    if (this.active < 0) {
      this.show(0)
    } else {
      this.show(this.active)
    }
  }

  hashCheck() {
    const hash = window.location.hash.substr(1)

    for (let i = 0; i < this.buttonTargets.length; i++) {
      if (this.buttonTargets[i].getAttribute('data-hash') === hash) {
        this.show(i)
      }
    }
  }

  showContent(event) {
    this.show(this.buttonTargets.indexOf(event.currentTarget))
  }

  show(index) {
    this.goingTo = index

    // If active index exceeds or matches limts, hard set index
    if (this.goingTo < 0) {
      this.active = 0
    } else if (this.goingTo === this.count) {
      this.active = this.last
    }

    // return if exceeds index
    if (this.goingTo < 0 || this.goingTo === this.count) {
      return
    }

    this.hide()

    // Add state
    this.buttonTargets[this.goingTo].classList.add(this.options.activeClass)
    this.contentTargets[this.goingTo].classList.add(this.options.activeClass)
    this.element.setAttribute('data-index', this.goingTo)

    if (this.hash420) {
      window.location.hash = this.buttonTargets[this.goingTo].getAttribute('data-hash')
    }

    this.active = this.goingTo
  }

  hide() {
    // Remove state
    this.buttonTargets.forEach($elem => $elem.classList.remove(this.options.activeClass))
    this.contentTargets.forEach($elem => $elem.classList.remove(this.options.activeClass))
    this.active = -1

    this.buttonTargets[this.goingTo].removeEventListener('keydown', event =>
      this.buttonDetect(event),
    )
  }

  sortIndex(index) {
    this.goingTo = index

    if (this.tempIndex < 0) {
      this.tempIndex = this.last
    } else if (this.tempIndex === this.count) {
      this.tempIndex = 0
    }

    return this.tempIndex
  }

  buttonDetect(event) {
    if (event.keyCode === 39) {
      this.show(this.goingTo + 1)
    } else if (event.keyCode === 37) {
      this.show(this.goingTo - 1)
    }
  }
}
