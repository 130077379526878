/* eslint-disable */
let isApiLoading;
let isApiLoaded =
(isApiLoading = false);

const callbacks = [];

const loadApi = function() {
  if (isApiLoading) { return; }
  isApiLoading = true;

  window.onYouTubePlayerAPIReady = function() {
    isApiLoaded = true;

    // Fire any callbacks pushed onto array below
    return Array.from(callbacks).map((callback) => callback());
  };

  // Load YT API async: https://developers.google.com/youtube/iframe_api_reference#Getting_Started
  const script = document.createElement('script');
  script.src = '//www.youtube.com/iframe_api';
  const firstScriptTag = document.getElementsByTagName('script')[0];
  return firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
};

export const PullYoutube = function(callback) {
  if (isApiLoaded) {
    return callback();
  } else {
    callbacks.push(callback);
    if (!isApiLoading) { return loadApi(); }
  }
};
