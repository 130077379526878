import anime from 'animejs'
import 'intersection-observer'
import Controller from './controller'

export default class extends Controller {
  static get targets() {
    return ['link', 'item', 'scroller', 'letter']
  }

  initialize() {
    this.active = 0
    this.scrollElement = document.scrollingElement || document.body || document.documentElement

    this.detect()

    this.linkTargets.forEach(elem =>
      elem.addEventListener('click', event => this.scrollToTarget(event)),
    )

    window.addEventListener('resize', () => this.detect())
    document.addEventListener('scroll', () => this.detect())
  }

  assignOffsets() {
    for (let i = 0; i < this.itemTargets.length; i++) {
      this.offsets.push(this.itemTargets[i].getBoundingClientRect().top + window.pageYOffset)
    }
  }

  detect() {
    this.offsets = []
    this.assignOffsets()

    for (let i = 0; i < this.offsets.length; i++) {
      if (window.pageYOffset >= this.offsets[i]) {
        this.active = i
      }
    }

    this.go(this.active)
  }

  go(index) {
    this.letterTargets.forEach(elem => elem.classList.remove('is-active'))
    this.letterTargets[index].classList.add('is-active')

    this.itemTargets.forEach(elem => elem.classList.remove('is-active'))
    this.itemTargets[index].classList.add('is-active')
  }

  scrollToTarget(event) {
    event.preventDefault()
    this.button = event.currentTarget
    this.goTo = this.linkTargets.indexOf(this.button)

    this.go(this.goTo)
    this.scrollTo(this.scrollElement, this.offsets[this.goTo] + 1, 500)
  }

  scrollTo(element, to, dur) {
    anime({
      targets: element,
      scrollTop: to,
      duration: dur,
      easing: 'easeOutCubic',
    })
  }
}
