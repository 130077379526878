import Controller from './controller'

export default class extends Controller {
  static get targets() {
    return ['count', 'item']
  }

  initialize() {
    this.activeIndex = 0
    this.length = this.itemTargets.length
    this.setHeight()
  }

  show(index) {
    this.itemTargets[this.activeIndex].classList.remove('is-active')
    this.itemTargets[index].classList.add('is-active')
    this.countTarget.textContent = index + 1
    this.setHeight()
    this.activeIndex = index
  }

  previous() {
    let index = this.activeIndex - 1
    if (index < 0) index = this.length - 1
    this.show(index)
  }

  next() {
    let index = this.activeIndex + 1
    if (index > this.length - 1) index = 0
    this.show(index)
  }

  setHeight() {
    this.element.style.minHeight = `${this.element.offsetHeight}px`
  }

  close() {
    this.emit('popup:close', { bubbles: true })
  }

  clickMarker() {
    this.emit('popup:click-marker', { bubbles: true })
  }
}
