let elementIdIndex = 0

export const elementId = () => {
  elementIdIndex += 1
  return `_element_${elementIdIndex}`
}

export default {
  elementId,
}

export const isTouch =
  window.matchMedia('(hover: none)').matches || window.matchMedia('(pointer: coarse)').matches

export const isReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches

// Get color luma for checking lightness of a hex
// https://stackoverflow.com/a/12043228
export const colorLuma = color => {
  /* eslint-disable no-bitwise */

  const c = color.substring(1) // Strip #
  const rgb = parseInt(c, 16) // Convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff // Extract red
  const g = (rgb >> 8) & 0xff // Extract green
  const b = (rgb >> 0) & 0xff // Extract blue

  return 0.2126 * r + 0.7152 * g + 0.0722 * b // Per ITU-R BT.709
}
