import Controller from './controller'

export default class extends Controller {
  // static get targets() {
  //   return ['text']
  // }

  initialize() {
    this.validClass = 'is-valid'
    this.invalidClass = 'is-invalid'
  }

  check() {
    // Clear visual validity state
    if (!this.element.value.trim()) {
      this.element.parentNode.classList.remove(this.invalidClass)
      this.element.parentNode.classList.remove(this.validClass)
      return
    }

    if (this.element.validity && this.element.validity.valid) {
      this.element.parentNode.classList.remove(this.invalidClass)
      this.element.parentNode.classList.add(this.validClass)
    } else {
      this.element.parentNode.classList.remove(this.validClass)
      this.element.parentNode.classList.add(this.invalidClass)
    }
  }

  input() {
    // Avoid checking validity until user blurred input once, to avoid annoying checks while typing
    if (this.hasBlurred) {
      this.check()
    }
  }

  blur() {
    this.hasBlurred = true
    this.check()
  }
}
