import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['content']
  }

  toggle(ev) {
    ev.preventDefault()
    this.contentTarget.classList.toggle('is-visible')
  }
}
