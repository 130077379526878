import { debounce } from 'lodash-es'
import Controller from './controller'

export default class extends Controller {
  static get targets() {
    return ['body', 'search']
  }

  initialize() {
    this.search = debounce(this.search, 250)
  }

  setData(data) {
    let content = ''
    data.forEach(study => {
      content += `<tr data-row>
        <td>
          <div class="map-table__tag" style="background-color:${study.color}"></div>
          ${study.region}
        </td>
        <td class="map-table__study" data-study-id="${study.id}">${study.name}</td>
      </tr>`
    })
    this.bodyTarget.innerHTML = content

    this.rows = Array.from(this.element.querySelectorAll('[data-row]')).map((element, i) => ({
      id: data[i].id,
      element,
      name: data[i].name,
      region: data[i].region,
    }))

    this.element.classList.add('has-rows')
    this.element.classList.add('is-shown')
  }

  click(ev) {
    const studyId = ev.target.dataset.studyId
    if (studyId) {
      this.emit('table:show', { bubbles: true, detail: { studyId } })
    }
  }

  search(ev) {
    const value = ev.target.value.trim().toLowerCase()
    if (value === this.value) return
    this.value = value
    this.filter()
  }

  filter({ triggerEvents = true } = {}) {
    if (!this.rows) return

    const studyIds = []
    this.rows.forEach(({ id, element, name, region }) => {
      const inActiveRegion = !this.activeRegions || this.activeRegions.indexOf(region) > -1
      const isMatch = inActiveRegion && (!this.value || name.toLowerCase().indexOf(this.value) > -1)

      if (isMatch) {
        element.style.display = ''
        studyIds.push(id)
      } else {
        element.style.display = 'none'
      }
    })

    if (studyIds.length > 0) {
      this.element.classList.add('has-rows')
    } else {
      this.element.classList.remove('has-rows')
    }

    if (triggerEvents) {
      this.emit('table:filter', { bubbles: true, detail: { studyIds } })
    }
  }

  setActiveRegions(regions) {
    this.activeRegions = regions
    this.filter({ triggerEvents: false })
  }

  setActiveStudy(studyId) {
    if (!studyId) {
      if (this.activeRow) {
        this.activeRow.element.classList.remove('is-active')
        this.activeRow = null
      }
      return
    }

    for (let i = 0; i < this.rows.length; i++) {
      if (studyId === this.rows[i].id) {
        this.activeRow = this.rows[i]
        this.activeRow.element.classList.add('is-active')
        break
      }
    }
  }

  reset() {
    this.searchTarget.value = ''
    this.value = ''
    this.setActiveStudy(null)

    this.rows.forEach(({ element }) => {
      element.style.display = ''
    })
  }
}
