import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['item']
  }

  focus(event) {
    const right = event.key === 'ArrowRight'
    const left = event.key === 'ArrowLeft'

    if (!right && !left) {
      return
    }

    const index = this.itemTargets.indexOf(event.currentTarget)
    const nextIndex = index + (right ? 1 : -1)

    if (this.itemTargets[nextIndex]) {
      const currentDropdown = this.dropdownInstance(index)
      const nextDropdown = this.dropdownInstance(nextIndex)

      if (nextDropdown) {
        // Focus dropdown.
        nextDropdown.triggerTarget.focus()
      } else {
        // Focus link.
        this.itemTargets[nextIndex].focus()
      }

      // Keep current open state when moving focus to next dropdown.
      if (currentDropdown && currentDropdown.isOpen) {
        currentDropdown.close()

        if (nextDropdown) {
          nextDropdown.open()
        }
      }
    }
  }

  // Get a `dropdown_controller` instance from a dropdown element.
  dropdownInstance(index) {
    return this.application.getControllerForElementAndIdentifier(
      this.itemTargets[index],
      'dropdown',
    )
  }
}
