import Controller from './controller'

export default class extends Controller {
  initialize() {
    if (!window.katex) {
      console.error('Katex must be included before rendering formulas')
      return
    }

    try {
      window.katex.render(this.element.textContent, this.element)
    } catch (error) {
      console.error('Error rendering formula', error)
    }
  }
}
