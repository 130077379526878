import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['container']
  }

  connect() {
    this.moveExistingElements()
    this.createContents()
    this.containerTarget.classList.add('contents-added')
  }

  createContents() {
    const wrapper = document.createElement('div')
    wrapper.classList.add('glossary-detail__item__menu')
    const header = document.createElement('h4')
    header.innerHTML = this.containerTarget.dataset.contentsHeader
    wrapper.appendChild(header)
    const elements = this.element.querySelectorAll('h4[data-contents], h5[data-contents]')
    const root = document.createElement('ol')
    let currentTarget = root
    let isIndented = false
    let lastRootLi

    // h4 becomes root contents
    // h5 becomes indented contents
    elements.forEach((e, i) => {
      if (e.tagName === 'H4') {
        if (isIndented) {
          // we need to back out to the root
          currentTarget = root
          isIndented = false
        }
      } else if (e.tagName === 'H5') {
        if (!isIndented) {
          // we need to step into the last root li and append to its
          // child ol until we hit another h4
          isIndented = true
          currentTarget = document.createElement('ol')
          // if they create a sub-heading first, we need to
          // manually create an li here to push to
          if (!lastRootLi) {
            lastRootLi = document.createElement('li')
            root.appendChild(lastRootLi)
          }
          lastRootLi.appendChild(currentTarget)
        }
      }
      const target = `${i + 1}-${encodeURI(e.innerHTML)}`
      const li = document.createElement('li')
      const link = document.createElement('a')
      e.setAttribute('id', target)
      link.innerHTML = e.innerHTML
      link.setAttribute('href', `#${target}`)
      li.appendChild(link)
      // most reliable way to track the last li to append children to
      // is to assign it by reference
      if (!isIndented) {
        lastRootLi = li
      }
      currentTarget.appendChild(li)
    })

    wrapper.appendChild(root)
    this.containerTarget.insertBefore(wrapper, this.containerTarget.childNodes[0])
  }

  moveExistingElements() {
    const firstElementContainer = document.createElement('div')
    firstElementContainer.classList.add('glossary-detail__item--right-aligned')
    Array.from(this.containerTarget.children).forEach(e => {
      firstElementContainer.appendChild(e.parentNode.removeChild(e))
    })
    this.containerTarget.appendChild(firstElementContainer)
  }
}
