import Flickity from 'flickity'
import Controller from './controller'

export default class extends Controller {
  static get targets() {
    return ['slides', 'previous', 'next']
  }

  static get options() {
    return {
      cellAlign: 'left',
      draggable: true,
      dragThreshold: 10,
      groupCells: true,
      pageDots: false,
      contain: true,
      prevNextButtons: false,
      wrapAround: false,
    }
  }

  initialize() {
    console.log(this.options)
    this.flickity = new Flickity(this.slidesTarget, this.options)
    this.flickity.slider.setAttribute('role', this.slidesTarget.getAttribute('role'))
    this.slidesTarget.removeAttribute('role')

    this.cellSelect()

    if (this.hasPreviousTarget) {
      this.previousTarget.addEventListener('click', () => this.previous())
    }

    if (this.hasNextTarget) {
      this.nextTarget.addEventListener('click', () => this.next())
    }

    // Fix Flickity viewport height calculation (see https://github.com/metafizzy/flickity/issues/205#issuecomment-246212994)
    window.addEventListener('load', () => this.flickity.resize())

    this.flickity.on('cellSelect', nothing => this.cellSelect(nothing))
  }

  cellSelect() {
    this.previousTarget.classList.remove('is-disabled')
    this.nextTarget.classList.remove('is-disabled')

    if (this.flickity.selectedIndex === 0) {
      this.previousTarget.classList.add('is-disabled')
    }

    if (this.flickity.selectedIndex === this.flickity.slides.length - 1) {
      this.nextTarget.classList.add('is-disabled')
    }
  }

  previous() {
    this.flickity.previous()
  }

  next() {
    this.flickity.next()
  }
}
