import anime from 'animejs'
import 'intersection-observer'
import Controller from './controller'

export default class extends Controller {
  static get targets() {
    return ['parent', 'list', 'item', 'button', 'next', 'previous']
  }

  static get options() {
    return {
      activeClass: 'is-active',
      comingClass: 'is-coming',
      offClass: 'is-off',
      disabledClass: 'is-disabled',
    }
  }

  initialize() {
    this.active = -1
    this.hideOffset = 380
    this.itemLength = this.itemTargets.length

    this.go(0)

    this.buttonTargets.forEach(elem => elem.addEventListener('click', () => this.onClick(elem)))
    this.nextTarget.addEventListener('click', () => this.go(this.active + 1))
    this.previousTarget.addEventListener('click', () => this.go(this.active - 1))
    window.addEventListener('resize', () => this.go(this.active))
  }

  onClick(elem) {
    this.go(this.buttonTargets.indexOf(elem))
  }

  go(index) {
    if (index === this.active) {
      return
    }

    this.nextTarget.classList.remove(this.options.disabledClass)
    this.previousTarget.classList.remove(this.options.disabledClass)

    if (index >= this.itemLength - 1) {
      index = this.itemLength - 1
      this.nextTarget.classList.add(this.options.disabledClass)
    }

    if (index <= 0) {
      index = 0
      this.previousTarget.classList.add(this.options.disabledClass)
    }

    this.itemTargets.forEach($elem => $elem.classList.remove(this.options.activeClass))
    this.buttonTargets.forEach($elem => $elem.classList.remove(this.options.activeClass))

    this.itemTargets[index].classList.add(this.options.activeClass)
    this.buttonTargets[index].classList.add(this.options.activeClass)

    this.active = index

    if (this.itemLength > 1) {
      this.slide(this.active)
    }
  }

  slide(index) {
    this.elem = this.itemTargets[index]
    this.offsetTop = this.elem.offsetTop

    if (index === 0) {
      this.height = this.elem.offsetHeight + this.hideOffset
    } else if (index > 0 && this.itemLength > 2 && index !== this.itemLength - 1) {
      this.height = this.elem.offsetHeight + this.hideOffset * 2
      this.offsetTop -= this.hideOffset
    } else {
      this.height = this.elem.offsetHeight + this.hideOffset
      this.offsetTop -= this.hideOffset
    }

    anime({
      targets: this.listTarget,
      translateY: -this.offsetTop,
      duration: 500,
      easing: 'easeOutCubic',
    })

    anime({
      targets: this.listTarget,
      height: this.height,
      duration: 500,
      easing: 'easeOutCubic',
    })
  }
}
